export async function getGithubRepositoryStats() {
  const response = await fetch(`https://api.github.com/repos/kong/insomnia`);

  if (!response.ok) {
    throw new Error('Failed to fetch data from GitHub');
  }

  const data = await response.json();

  return data;
}
