import { useEffect, useState } from 'react';
import { Button, DialogTrigger, Link, Popover } from 'react-aria-components';
import ApiMock from '~/assets/api-mock.svg';
import Insomnia from '~/assets/insomnia.svg';
import InsoLogo from '~/assets/inso-logo.svg';

import { Icon } from '../icon';
import { twMerge } from 'tailwind-merge';
import { InsomniaLogoIcon } from '../icons/insomnia-logo';
import { InsomniaLabelIcon } from '../icons/insomnia-label';
import { getGithubRepositoryStats } from '~/services/ai.client';
import { formatNumberWithCommas } from './utils';

const PRODUCTS = [
  {
    href: 'https://insomnia.rest/products/insomnia',
    data: {
      product_name: 'Insomnia',
      product_description: 'The Collaborative API Client and Design Tool',
      product_icon: {
        url: Insomnia,
      },
    },
  },
  {
    href: 'https://insomnia.rest/features/api-mocking',
    data: {
      product_name: 'API Mocking',
      product_description: 'Create API mocks for your APIs to develop and ship faster',
      product_icon: {
        url: ApiMock,
      },
    },
  },
  {
    href: 'https://insomnia.rest/products/inso',
    data: {
      product_name: 'Insomnia Inso',
      product_description: 'Insomnia CLI for building out testing and deployment CI/CD pipelines for APIs.',
      product_icon: {
        url: InsoLogo,
      },
    },
  },
];

export function Header() {
  const [menuOpen, setMenuOpen] = useState(false);
  const [stars, setStars] = useState('...');

  useEffect(() => {
    getGithubRepositoryStats().then(({ stargazers_count }) => {
      setStars(!isNaN(stargazers_count) ? formatNumberWithCommas(stargazers_count) : '...');
    });
  }, []);

  return (
    <div className="z-50 flex w-screen justify-center bg-transparent text-center">
      <div className="flex w-full max-w-[1024px] flex-col flex-wrap items-center justify-between gap-8 px-[24px] py-[8px] lg:flex-row lg:px-0 lg:py-[24px]">
        <div className="grid w-full items-center [grid-template-columns:35px_1fr_1fr] lg:flex lg:w-fit lg:justify-between ">
          <Link href="/">
            <InsomniaLogoIcon />
          </Link>
          <Link href="https://konghq.com">
            <InsomniaLabelIcon />
          </Link>
          <Button
            aria-label="Open navigation menu"
            className="menu-trigger block cursor-pointer justify-self-end outline-none lg:hidden"
            onPress={() => setMenuOpen(!menuOpen)}
          >
            <Icon icon="bars" className="h-6 w-6 text-zinc-800" />
          </Button>
        </div>

        <div
          className={twMerge('w-full flex-1 lg:flex lg:w-auto lg:items-center', menuOpen ? 'block' : 'hidden')}
          id="primary-menu"
        >
          <nav className="m-1 w-full list-none items-center justify-between p-0 pt-0 text-left text-base text-gray-700 lg:flex">
            <div className="w-fit py-[2rem] pr-[2rem] lg:py-0">
              <a className="flex rounded border border-zinc-800" href="https://github.com/Kong/insomnia">
                <span className="flex h-[24px] items-center justify-center px-[5px] py-[2.5px] text-[12px] font-bold">
                  <svg className="fill-zinc-800" viewBox="0 0 1024 1024" width="15" height="15">
                    <path d="M512 0C229.252 0 0 229.25199999999995 0 512c0 226.251 146.688 418.126 350.155 485.813 25.593 4.686 34.937-11.125 34.937-24.626 0-12.188-0.469-52.562-0.718-95.314-128.708 23.46-161.707-31.541-172.469-60.373-5.525-14.809-30.407-60.249-52.398-72.263-17.988-9.828-43.26-33.237-0.917-33.735 40.434-0.476 69.348 37.308 78.471 52.75 45.938 77.749 119.876 55.627 148.999 42.5 4.654-32.999 17.902-55.627 32.501-68.373-113.657-12.939-233.22-56.875-233.22-253.063 0-55.94 19.968-101.561 52.658-137.404-5.22-12.999-22.844-65.095 5.063-135.563 0 0 42.937-13.749 140.811 52.501 40.811-11.406 84.594-17.031 128.124-17.22 43.499 0.188 87.314 5.874 128.188 17.28 97.689-66.311 140.686-52.501 140.686-52.501 28 70.532 10.375 122.564 5.124 135.499 32.811 35.844 52.626 81.468 52.626 137.404 0 196.686-119.751 240-233.813 252.686 18.439 15.876 34.748 47.001 34.748 94.748 0 68.437-0.686 123.627-0.686 140.501 0 13.625 9.312 29.561 35.25 24.562C877.436 929.998 1024 738.126 1024 512 1024 229.25199999999995 794.748 0 512 0z" />
                  </svg>
                </span>
                <span className="flex h-[24px] items-center justify-center border-l border-zinc-800 px-[5px] align-middle text-[12px] font-bold">
                  <svg className="inline-block fill-zinc-800 pr-[4px]" viewBox="0 0 896 1024" width="15" height="18">
                    <path d="M896 384l-313.5-40.781L448 64 313.469 343.219 0 384l230.469 208.875L171 895.938l277-148.812 277.062 148.812L665.5 592.875 896 384z" />
                  </svg>
                  <span className="text-zinc-800">{stars}</span>
                </span>
              </a>
            </div>

            <div className="py-2 lg:py-0">
              <DialogTrigger>
                <Button className="flex cursor-pointer items-center space-x-1.5 lg:flex-row lg:items-center lg:space-x-1.5">
                  <span className="text-zinc-800 text-opacity-80 transition duration-300 hover:text-opacity-100">
                    Products
                  </span>
                  <Icon icon="chevron-down" className="h-3 w-3 text-zinc-800" />
                </Button>
                <Popover className="max-w-[512px] rounded-lg bg-white p-[16px] shadow-lg lg:pointer-events-auto lg:absolute lg:w-auto">
                  <ul>
                    {PRODUCTS.map(({ href, data }, i) => (
                      <li key={i} className="rounded px-[12px] py-[16px] hover:bg-gray-100">
                        <Link href={href}>
                          <h4 className="flex items-center text-[18px] font-medium text-black">
                            {!!data?.product_icon?.url && (
                              <img src={data.product_icon.url} className="mr-2 h-8 w-8" alt="img" />
                            )}
                            {data.product_name}
                          </h4>
                          <p className="pt-[8px] text-[16px] text-gray-400">{data.product_description}</p>
                        </Link>
                      </li>
                    ))}
                  </ul>
                </Popover>
              </DialogTrigger>
            </div>

            <div className="py-2 lg:py-0">
              <Link
                href="https://insomnia.rest/docs"
                className="text-zinc-800 text-opacity-80 transition duration-300 hover:text-opacity-100"
              >
                Docs
              </Link>
            </div>

            <div className="py-2 lg:py-0">
              <Link
                href="https://insomnia.rest/pricing"
                className="text-zinc-800 text-opacity-80 transition duration-300 hover:text-opacity-100"
              >
                Pricing
              </Link>
            </div>

            <div className="py-2 lg:py-0">
              <Link
                href="https://insomnia.rest/plugins"
                className="pr-0 text-zinc-800 text-opacity-80 transition duration-300 hover:text-opacity-100"
              >
                Plugins
              </Link>
            </div>

            <div className="py-2 lg:py-0">
              <Link
                href="/app/authorize?redirect=/ai"
                className="text-zinc-800 text-opacity-80 transition duration-300 hover:text-opacity-100"
              >
                Login
              </Link>
            </div>

            <div className="w-max py-2 lg:py-0">
              <Link
                href="https://insomnia.rest/pricing"
                className="button group m-0 flex cursor-pointer flex-row items-center rounded border border-zinc-800 px-[14px] py-[12px] align-middle font-normal leading-none tracking-wide text-zinc-800 no-underline opacity-80 outline-none transition hover:border-[#4000BF] hover:bg-[#4000BF] hover:text-white hover:opacity-100"
              >
                Get Started for Free
                <Icon icon="arrow-right" className="ml-2 h-4 w-4 text-zinc-800 group-hover:text-white" />
              </Link>
            </div>
          </nav>
        </div>
      </div>
    </div>
  );
}
