import { type LoaderFunctionArgs, redirect, type ActionFunctionArgs } from '@remix-run/node';
import { Outlet } from '@remix-run/react';
import invariant from 'tiny-invariant';
import { Header } from '~/components/ai/header';
import Footer from '~/partials/footer';
import { getUserIfExist } from '~/services/user.server';
import { logout } from '~/session/session.server';

export async function loader({ request }: LoaderFunctionArgs) {
  const user = await getUserIfExist(request);

  if (user) {
    return redirect('/ai-runners');
  }

  return null;
}

export async function action({ request }: ActionFunctionArgs) {
  const formData = await request.formData();

  const action = formData.get('action');
  invariant(action, 'Action is required');

  if (action === 'logout') {
    await logout(request);
  }
}

export default function AiLayout() {
  return (
    <div
      className="flex h-full flex-col justify-between"
      style={{
        background: 'linear-gradient(172.51deg, rgb(245 245 245) 18.54%, rgb(229 209 255) 102.27%)',
      }}
    >
      <Header />
      <Outlet />
      <Footer className="border-none bg-transparent" />
    </div>
  );
}
